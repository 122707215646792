import type { MessageDto } from '@repo/test-lib/message-dto';
import React, { useCallback, useState } from 'react';
import { useReadQuery, useWriteMutation } from '../store/api';

export default function HomePage(): React.JSX.Element {
  const { data: message } = useReadQuery();
  const [write] = useWriteMutation();
  const [value, setValue] = useState('');

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.currentTarget.value);
  }, []);

  const onClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      event.preventDefault();
      const dto: MessageDto = { text: value === '' ? undefined : value };
      await write(dto);
      setValue('');
    },
    [value, setValue, write],
  );

  return (
    <div className="m-8 space-y-4">
      <div>Hello {message?.text ?? ''}</div>
      <form>
        <input className="border" onChange={onChange} value={value} />
        <button className="border bg-gray-100 px-2" onClick={onClick}>
          send
        </button>
      </form>
    </div>
  );
}
